<template>
  <v-container fluid>
    <v-card-title>
      멘토
      <v-spacer />
      <v-btn outlined to="/mentor/create">
        <v-icon left>mdi-plus</v-icon>
        등록
      </v-btn>
    </v-card-title>
    <v-card outlined>
      <v-data-table
        :headers="headers"
        :items="listMentor"
        :items-per-page="req.offset"
        sort-by="id"
        sort-desc
        @click:row="
          (item) => {
            $router.push(`${$route.path}/${item.id}`)
          }
        "
      >
        <template v-slot:item.createdAt="{ item }">
          {{ day(item.createdAt) }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs } from '@vue/composition-api'
import { day } from '@/helper'

export default defineComponent({
  setup(props, { root }) {
    const state = reactive({
      listMentor: [],
      req: {
        offset: 15,
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: '이름', value: 'name' },
        { text: '학교', value: 'school' },
        { text: '가입일자', value: 'createdAt' },
      ],
    })

    const getListMentor = async () => {
      state.listMentor = await root.$store.dispatch('mentor/getListMentor', {})
    }

    onBeforeMount(() => {
      getListMentor()
    })

    return {
      ...toRefs(state),
      getListMentor,
      day,
    }
  },
})
</script>
